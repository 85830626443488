<template>
  <div class="text-center">
    <button
      :id="id"
      :name="name"
      :class="classBoostrap"
      type="submit"
      :disabled="disabledButton"
      @click="eventClick()"
    >
      {{ description }}
    </button>
  </div>
</template>

<script>
export default {
  name: "f-button",

  props: {
    id: {},
    name: {},
    description: {
      default: "button",
    },
    onClickButton: {
      type: Function,
    },
    routeNamePush: {},
    classBoostrap: {
      default: "",
    },
    disabledButton: {
      default: false,
    },
  },
  methods: {
    eventClick() {
      if (this.routeNamePush) {
        this.$router.push({ name: this.routeNamePush });
      }

      if (!this.onClickButton) {
        return;
      }
      this.onClickButton();
    },
  },
};
</script>

<style scoped>
button[type="submit"] {
  font-weight: 600;
  background: var(--vc-clr-primary);
  border: 0;
  border-radius: 14px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}
button[type="submit"]:disabled {
  font-weight: 600;
  background: var(--color-disabled-button);
  border: 0;
  border-radius: 14px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}
button[type="submit"]:hover {
  background: var(--vc-c-green);
}
button[disabled]:hover {
  background: var(--color-disabled-button);
}
.custom-secundary-btn{
    background-color:  var(--color-secundary-btn) !important;
}
</style>