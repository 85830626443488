<template>
  <section>
    <div class="container-header">
      <!-- ======= Header ======= -->
      <header id="header">
        <div class="d-flex row" style="width: 100%">
          <div class="logo mr-auto col-md-12 col-lg-7">
            
            
              <img class="img-fluid img-lanapati" rel="preload" :src="showImageLogo()" style="width: 10%; height: auto" alt="" />
            
          </div>
          <div class="nav-menu col-md-12 col-lg-5">
            <div class="row" style="float: right; width: 89%; max-width: 387px">
              <div  class="col-lg-6 col-md-12 pr-1" style="text-align: center">
                <a href="https://play.google.com/store/apps/details/Lana_Pa_Ti?id=com.fiinlab.wallet&hl=es_DO" >
                  <button-credit description="Descarga la app"/>
                </a>
                </div>
              <!---  <button-credit
                  class="custom-width"
                  v-if="!getIsLogin && appOn==='1' && !getReadingDocuments"
                  :onClickCredit="activeLogin"
                  description="Inicia sesión"
                />
              </div>
              <div  class="col-lg-6 col-md-12 pr-1" style="text-align: center">
                <button-credit
                v-if="showButtonCredit && appOn==='1' && !getReadingDocuments"
                  :onClickCredit="registerLoan"
                  description="Solicita tu crédito"
                />
              </div>
              <div  class="col-lg-6 col-md-12 pr-1 d-lg-none" style="text-align: center">
                <button-credit
                v-if="!getIsLogin && appOn==='1' && !getReadingDocuments"
                  class=" custom-login"
                  :onClickCredit="activeLogin"
                  description="Inicia sesión"
                />
              </div>--->
            </div>
          </div>
          <!-- .nav-menu -->
            
          <!--end burguer main -->
        </div>
      </header>
    </div>
  </section>
</template>

<script>
import { getImgUrl } from "../core/util/image";
import FButton from "../components/atoms/FButton.vue";
import ButtonCredit from "./atoms/ButtonCredit.vue";


import {  mapActions, mapGetters } from "vuex";


export default {
  components: {
    FButton,
    ButtonCredit,
  },
  data(){
    return {
      appOn: process.env.VUE_APP_ON
    }
  },
  props: ["showButtonCredit", "showButtonLogin", "showButtonLogin"],
  computed: mapGetters([
    "getIsLogin",
    "getShowNavBar",
    "getReadingDocuments"
  ]),
  methods: {
    ...mapActions(["changeStep","hideNavBar"],),
    showImageLogo() {
      return getImgUrl("logolanapati.png", "png");
    },
    //registerLoan() {
     // this.changeStep(1);
    //},
    //activeLogin(){
      //this.hideNavBar();
    //},
  },
  mounted(){
   
  },
  watch: {
    

  }
};
</script>

<style  scoped>
/*--------------------------------------------------------------
# header
--------------------------------------------------------------*/
.img-logo {
  /*width: 225px;
  height: 100px;*/
  max-height: 60px;
  padding: 0.5rem;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 70px;
  transition: all 0.5s;

  transition: all 0.5s;
  padding: 10px 0;

  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

  background-color: var(--color-header);
}

.container-header {
  z-index: 997;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #90bc23;
  box-shadow: 0px 15px 10px -15px rgb(0 0 0 / 15%);
}

#header .logo h1 {
  font-size: 26px;
  padding: 0 0 0 8px;
  margin: 11px 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  border-left: 8px solid #e96b56;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #545454;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
  margin-left: 2px;
}

.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.button-contact {
  font-weight: 600;
  background: #fff;

  border-radius: 20px;
  border-color: #e7e7e7;
  padding: 7px 19px;
  color: grey;
  transition: 0.4s;
  border: 1px solid #9b9b9b;
  width: 100%;
  font-size: 0.75rem;
}

.button-contact i {
  font-size: 1.5rem;
  display: inline-block;
  top: 5.5px;
  position: absolute;
  left: 0.65rem;
}

.button-contact-topbar {
  font-weight: 600;
  background: var(--vc-clr-primary);

  border-radius: 20px;
  border-color: #e7e7e7;
  padding: 7px 19px;
  color: #fff;
  transition: 0.4s;
  border: 1px solid var(--vc-clr-primary);
  width: 100%;
  font-size: 0.6rem;
}

.button-contact-topbar i {
  font-size: 1.3rem;
  display: inline-block;
  top: 5.5px;
  position: absolute;
  left: 1.9rem;
}

.div-without-padding {
  padding: 0;
}
.img-lanapati{
  cursor: pointer;
}
</style>