<template>
  <!-- ======= Footer ======= -->
  <footer
    id="footer"
    v-bind:style="[
      showNavBar ? { 'margin-bottom': '50px' } : { 'margin-bottom': '0' },
    ]"
  >
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-3 col-md-6 footer-links"
            style="text-align: center; color: #94b93f"
          >
            <i class="bx bx-navigation"></i>
            <a class="footer-item" href="https://www.yastas.com/wps/themes/html/YastasOrg/media/Yastas/files/avisosPrivacidad/Aviso_de_Privacidad_Integral_Lana_Pa_Ti_V1.pdf"
              >Aviso de Privacidad y derechos ARCO</a
            >
          </div>
          <div
            class="col-lg-3 col-md-6 footer-links"
            style="text-align: center; color: #94b93f"
          >
            <a class="footer-item"></a>
          </div>
          <div
            class="col-lg-3 col-md-6 footer-links"
            style="text-align: center; color: #94b93f"
          >
            <a class="footer-item"></a>
          </div>
          <div
            class="col-lg-3 col-md-6 footer-links"
            style="text-align: center; color: #94b93f"
          >
            <i class="bx bx-navigation"></i>
            <a class="footer-item" href="assets/TyC.pdf">Terminos y condiciones</a>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        Red Yastas SA de CV. &copy; Copyright 2024.
      </div>
    </div>
  </footer>
  <!-- End Footer -->
</template>

<script>
export default {
  props: ["showNavBar"],
};
</script>

<style  scoped>
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: var(--vt-background-card-dark);
  padding: 0 0 12px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #404040;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #e96b56;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #35587d;
}

#footer .footer-top {
  background: var(--vt-background-card-dark);
  border-top: 1px solid #999a98;
  border-bottom: 1px solid #999a98;
  padding: 20px 0 0px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #545454;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #35587d;
  color: #fff;
  text-decoration: none;
}
.footer-item {
  color: white;
}
.footer-item:hover {
  color: #35587d;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 20px;
  font-size: 0.7rem;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #93c01f;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #35587d;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .copyright {
  text-align: center;
  padding-top: 12px;
  font-size: 0.7rem;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
</style>