<template>
  <section>
    <div class="comment">
      <div class="row m-0 service-section pb-2">
        <div class="col-lg-12">
          <div class="section-title p-0 card card-section-service pt-2">
            <h2>¿Qué es LanaPa'ti?</h2>
          </div>
        </div>
      </div>
      <div class="row m-0 service-section pb-2 service-slice">
        <div class="col-lg-12 service-slice">
          <div class="section-title p-0 card service-slice">
            <div class="card-body">
              <div class="row" style="justify-content: center">
                <div v-if="showMobileImage" class="col-lg-4 col-md-12">
                  <img
                    class="phone-mobile-img"
                    :src="changeImagePhone()"
                    width="50%"
                    height="50%"
                    alt=""
                  />
                </div>
                <div class="col-lg-8 col-md-12 card-carousel-service">
                  <carousel
                    :items-to-show="itemsToshow"
                    :autoplay="3000"
                    :wrapAround="true"
                  >
                    <slide v-for="slide in slides" :key="slide.id">
                      <!--{{ slide }} -->
                      <div style="col-6">
                        <div class="info-box mb-4 mt-4 item-slice" style="">
                          <!-- <i class="bx" :class="slide.iconBox"></i> -->
                          <img
                            class="bx bxs-credit-card"
                            v-bind:src="getImgCard(slide.imgCard, 'svg')"
                            width=""
                            height=""
                            alt=""
                          />
                          <p>{{ slide.description }}</p>
                        </div>
                      </div>
                    </slide>

                    <template #addons>
                      <navigation />
                      <pagination />
                    </template>
                  </carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { getImgUrl } from "../../core/util/image";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: ["showMobileImage"],
  data: () => ({
    slides: [
      {
        id: 1,
        description: "Línea de crédito hasta de $30,000.",
        iconBox: "bx-credit-card-alt",
        imgCard: "receive_money.svg",
      },
      {
        id: 2,
        description: "Pagas $110 semanales por cada $1,000 dispuestos.",
        iconBox: "bx-credit-card-alt",
        imgCard: "payment.svg",
      },
      {
        id: 3,
        description:
          "Puedes solicitar tu línea de crédito aquí o desde la App.",
        iconBox: "bx-mobile",
        imgCard: "line_credit.svg",
      },
      {
        id: 4,
        description: "Desembolso en tu cuenta de débito.",
        iconBox: "bx-mobile",
        imgCard: "disbursement.svg",
      },
      {
        id: 5,
        description: "Redisponible cuando lo necesitas.",
        iconBox: "bx-mobile",
        imgCard: "cash.svg",
      },
      {
        id: 6,
        description: "Pagos semanales durante 3 meses.",
        iconBox: "bx-mobile",
        imgCard: "calendar_payment.svg",
      },
    ],
    windowWidth: window.innerWidth,
    itemsToshow: 3.5,
  }),
  watch: {
    windowWidth(newWidth, oldWidth) {
      this.changeItemsToShow();
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.changeItemsToShow();
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    changeItemsToShow() {
      if (this.windowWidth < 400) {
        this.itemsToshow = 1.5;
      } else if (this.windowWidth < 600) {
        this.itemsToshow = 2.5;
      } else {
        this.itemsToshow = 3.5;
      }
    },
    getImgCard(name, type) {
      return getImgUrl(name, type);
    },
    changeImagePhone() {
      // if (this.windowWidth < 400) {
      //   return getImgUrl("phone1x.png", "png");
      // } else 
      if (this.windowWidth < 600) {
        return getImgUrl("phone2x.webp", "webp");
      } else {
        return getImgUrl("phone3x.webp", "webp");
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.service-section {
  background: var(--color-background);
}
.card-section-service {
  background: var(--color-background);
}
.service-slice {
  background: var(--color-background-gray);
}
.item-slice {
  padding: 5px;
  height: 200px;
  width: 150px;
  border-radius: 10px;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
  border: 0;
}

.section-title h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;
  color: var(--color-text-card);
  font-family: Lato;
}

.info-box {
  color: var(--vt-c-gray-light);
  text-align: center;
  /*box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);*/
  background: #fff;
  padding: 20px 0 30px 0;
}

.info-box i {
  font-size: 32px;
  color: var(--vc-clr-primary);
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #fef5f4;
}
.info-box img {
  font-size: 32px;
  color: #93c01f;
  /* border-radius: 50%; */
  padding: 8px;
  border: 0 dotted #fef5f4;
  width: 3.3rem;
}

.info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.info-box p {
  padding: 5px;
  line-height: 24px;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-mobile-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: auto;
  position: absolute;
  z-index: 100;
  margin-top: -5.2rem;
}
.card-carousel-service {
  margin-top: 20px;
}
@media screen and (max-width: 1590px) {
  .phone-mobile-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: auto;
    position: absolute;
    z-index: 100;
    margin-top: -5.2rem;
  }
}
@media screen and (max-width: 1090px) {
  .phone-mobile-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: auto;
    position: absolute;
    z-index: 100;
    margin-top: -5.2rem;
  }
}

@media screen and (max-width: 999px) {
  .phone-mobile-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    height: auto;
    position: relative;
    z-index: 100;
    margin-top: -5.2rem;
  }
}
@media screen and (max-width: 700px) {
  .phone-mobile-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: auto;
    position: relative;
    z-index: 100;
    margin-top: -5.2rem;
  }
}
@media screen and (max-width: 599px) {
  .phone-mobile-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    height: auto;
    position: relative;
    z-index: 0;
    margin-top: -4.5rem;
  }
  .card-carousel-service {
    margin-top: -40px;
  }
}
@media screen and (max-width: 400px) {
}
</style>