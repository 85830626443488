
import { createApp } from "vue";
import { createWebHashHistory, createRouter } from "vue-router";
import HomeView from '@/app/components/HomeView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/app/components/AboutView.vue')
  },
  {
    path: '/faq-section',
    name: 'faq-section',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/app/components/sections/FaqSection.vue')
  },
  {
    path: '/documents/privacy',
    name: 'document-privacy',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/app/components/documents/DocumentPrivacyWallet.vue')
  },
]



const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
});

export default router;
