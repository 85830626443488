import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";


const ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  isLogin: false,
  encryptionSecret: process.env.VUE_APP_SECRET_KEY,
});

const store = createStore({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],

  state: {
    showButtonsCredit: true,
    showNavBar: true,
    step: 1,
    readingDocuments: false,
  },
  getters: {
    getButtonsCredit: (state) => state.showButtonsCredit,
    getShowNavBar: (state) => state.showNavBar,
    //reading documents
    getReadingDocuments: (state) => state.readingDocuments,
  },
  mutations: {
    SHOW_BUTTONS_CREDIT(state, payload) {
      state.showButtonsCredit = payload;
    },
    setButtonsCredit: (state, payload) => (state.showButtonsCredit = payload),
    getIsLogin: (state) => state.isLogin,
    setShowNavBar: (state, payload) => (state.showNavBar = payload),
    setReadingDocuments:  (state, payload) => (state.readingDocuments = payload),
    setIsLogin: (state, payload) => (state.isLogin = payload),
  },
  actions: {
    showNavBar: (context) => {
      context.commit("setShowNavBar", true);
    },
    hideNavBar: (context) => {
      context.commit("setShowNavBar", false);
    },
    hideButtonsCredit: (context) => {
      context.commit("setButtonsCredit", false);
    },
    showButtonsCredit: (context) => {
      context.commit("setButtonsCredit", true);
    },
    changeStep: (context, payload) => {
      context.commit("setStep", payload);
    },
    onlyReadingDocuments: (context) => {
        context.commit("setReadingDocuments", true);
        context.commit("setShowNavBar", false);
    },
    notReadingDocuments: (context) => {
        context.commit("setReadingDocuments", false);
        context.commit("setShowNavBar", true);
    },
  },
});

export default store;