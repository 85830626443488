export const getImgUrl = (name, type,) => {
  let imagen = () => { };
  if (type === 'svg') {
    imagen = require.context('@/assets/img', false, /\.svg$/);
  } else if (type === 'png') {
    imagen = require.context('@/assets/img', false, /\.png$/);
  } else if (type === 'avif'){
    imagen = require.context('@/assets/img', false, /\.avif$/);
  } else if (type === 'webp'){
    imagen = require.context('@/assets/img', false, /\.webp$/);
  } 

  return imagen('./' + name);
};