import { createApp } from 'vue'
import App from './App.vue'
import router from './app/core/router'
import 'vue3-toastify/dist/index.css';
import store from "./app/core/store/index";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'boxicons/css/boxicons.min.css'
import 'boxicons/dist/boxicons.js'



const app = createApp(App);
app.use(store);
app.use(router).mount('#app')

