<template>
  <section id="steps-section">
    <div class="requirement">
      <div class="row m-0 pb-2">
        <div class="col-lg-12">
          <div class="section-title p-0 card card-seccions">
            <h2 class="mt-3 mb-3">
              En tan sólo 3 pasos puedes obtener tu crédito
            </h2>
            <div class="card-body">
              <div class="row" style="justify-content: center">
                <div class="col-lg-6 col-md-12">
                  <div class="info-box-step mb-3">
                    <i class="bx">1</i>

                    <p>
                      <strong> Compartenos tus datos</strong> en la solicitud de
                      crédito.
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-12">
                  <div class="info-box-step mb-3">
                    <i class="bx">2</i>

                    <p>
                      Te notificamos el
                      <strong>resultado de tu solicitud.</strong>
                    </p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-12">
                  <div class="info-box-step mb-3">
                    <i class="bx">3</i>

                    <p>
                      <strong
                        >¡Disfruta de tu crédito digital en cualquier momento y
                        lugar!</strong
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.section-title {
  text-align: center;
  padding-bottom: 30px;
  border: 0;
  height: auto;
  width: 100%;
}

.section-title h2 {
  font-size: 1.8rem;
  font-weight: 600;

  position: relative;
  color: var(--color-text-card);
}

.info-box-step i {
  font-size: 5rem;
  color: var(--vc-c-green);
  border-radius: 50%;
  padding: 8px;
  font-weight: 600;
}

.info-box-step h3 {
  font-size: 14px;
  color: var(--vt-c-black);
  font-weight: 700;
  margin: 10px 0;
}

.info-box-step p {
  padding: 0;
  line-height: 24px;
  font-size: 1.2rem;
  margin-bottom: 0;
  padding-left: 10%;
  padding-right: 10%;
}

.info-box-step {
  color: var(--color-text-card-body);
  text-align: center;
  padding: 20px 0 30px 0;
  height: auto;
  width: 100%;
}
</style>