<template>
  <div class="requirement">
    <div class="row m-0 pb-2 pt-2">
      <div class="col-lg-12">
        <div class="section-title info-box card card-seccions">
          <h2>Lo que necesitas para solicitar tu crédito</h2>
          <div class="card-body">
            <div class="row" style="justify-content: center">
              <div class="col-lg-2 col-md-12">
                <div class="info-box-req mb-3">
                  <img
                    class="bx bxs-credit-card"
                    src="@/assets/img/require_18_more.svg"
                    alt=""
                  />

                  <p>Ser mayor de edad</p>
                </div>
              </div>

              <div class="col-lg-2 col-md-12">
                <div class="info-box-req mb-3">
                  <img
                    class="bx bxs-credit-card"
                    src="@/assets/img/require_INE.svg"
                    alt=""
                  />
                  <p>Identificación oficial vigente</p>
                </div>
              </div>

              <div class="col-lg-2 col-md-12">
                <div class="info-box-req mb-3">
                  <img
                    class="bx bxs-credit-card"
                    src="@/assets/img/require_mobile_phone.svg"
                    alt=""
                  />
                  <p>Tener un correo electrónico y un número activo</p>
                </div>
              </div>

              <div class="col-lg-2 col-md-12">
                <div class="info-box-req mb-3">
                  <img
                    class="bx bxs-credit-card"
                    src="@/assets/img/require_account_bank.svg"
                    alt=""
                  />
                  <p>Contar con una cuenta de débito propia</p>
                </div>
              </div>

              <div class="col-lg-2 col-md-12">
                <div class="info-box-req mb-3">
                  <!-- <i class="bx bxl-android"></i> -->
                  <img
                    class="bx bxs-credit-card"
                    src="@/assets/img/require_documents.svg"
                    alt=""
                  />
                  <p>Comprobante de domicilio vigente, no mayor a tres meses</p>
                </div>
              </div>
            </div>
          </div>
          <a href="https://play.google.com/store/apps/details/Lana_Pa_Ti?id=com.fiinlab.wallet&hl=es_DO" >
            <button-credit description="Descarga la app"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonCredit from "../atoms/ButtonCredit.vue";
import {  mapActions } from "vuex";

export default {
  components: {
    ButtonCredit,
  },
  props: ["showButtonCredit"],
  data(){
    return {
      appOn: process.env.VUE_APP_ON
    }
  },
  methods: {
    ...mapActions(["changeStep"],),
    registerLoan() {
      this.changeStep(1);
    },
  }
};
</script>

<style scoped>
.section-title {
  text-align: center;
  padding-bottom: 30px;
  height: auto;
  width: 100%;
}

.section-title h2 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  color: var(--color-text-card);
}
.info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #fff;
  padding: 20px 0 30px 0;
  border: 0px;
}
.requirement button[type="submit"] {
  font-weight: 600;
  background: var(--vc-clr-primary);
  border: 0;
  border-radius: 14px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.requirement button[type="submit"]:hover {
  background: var(--vc-c-green);
}

.info-box-req i {
  font-size: 3.2rem;
  color: #fff;
  background: var(--vc-c-green);
  border-radius: 50%;
  padding: 8px;
  margin-bottom: 7px;
  margin-top: 7px;
  /* border: 2px dotted #fef5f4; */
}
.info-box-req img {
  font-size: 32px;
  color: #93c01f;
  border-radius: 50%;
  padding: 8px;
  border: 0 dotted #fef5f4;
  width: 5rem;
}

.info-box-req h3 {
  font-size: 14px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.info-box-req p {
  padding: 0;
  line-height: 24px;
  font-size: 1.2rem;
  margin-bottom: 0;
}

.info-box-req {
  color: var(--color-text-card-body);
  text-align: center;
  padding: 20px 0 30px 0;
  height: auto;
  width: 80%;
}
</style>