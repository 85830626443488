
<template>
  <div class="container-app">
    <Header 
    :showButtonCredit="!showNavBarCredit" 
    />
    <RouterView />
    <ContactSection />
    <Footer :showNavBar="showNavBarCredit" />
    <nav-bar-credit v-if="showNavBarCredit && getShowNavBar && appOn ==='1'"/>
  </div>
</template>

<script>
import { RouterLink, RouterView } from "vue-router";
import Header from "@/app/components/Header.vue";
import Footer from "@/app/components/Footer.vue";
import ContactSection from "@/app/components/sections/ContactSection.vue";
import NavBarCredit from "./app/components/NavBarCredit.vue";
import { mapGetters } from 'vuex';
import { ref } from "vue";

export default {
  components: {
    RouterLink,
    RouterView,
    Header,
    Footer,
    ContactSection,
    NavBarCredit,
  },
  
  data: () => ({
    windowWidth: window.innerWidth,
    showNavBarCredit: false,
    appOn: process.env.VUE_APP_ON
  }),
  watch: {
    windowWidth(newWidth, oldWidth) {
      this.changeShowNavBar();
    },
    
  },
  created() {
    document.title = "LanaPa'ti";

  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.changeShowNavBar();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    changeShowNavBar() {
      if (this.windowWidth < 600) {
        this.showNavBarCredit = true;
      } else {
        this.showNavBarCredit = false;
        
      }
    },


   
  },
};
</script>


<style>
@import "@/assets/base.css";

@import '@/assets/fonts/amorSans/stylesheet.css';



h2,
h3,
h4,
h5,
h6 {
  font-family: 'Amor Sans Pro';
}

p,
span,
a {
  font-family: 'Amor Sans Pro';
}

button {
  font-family: 'Amor Sans Pro';
}

.container-app {
  /*max-width: 1280px;*/
  margin: 0 auto;
  /*padding: 2rem;*/

  font-weight: normal;
}

.card-seccions {
  background: var(--color-background-card) !important;
}

header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

a,
.green {
  text-decoration: none;
  color: hsla(160, 100%, 37%, 1);
  transition: 0.4s;
}

@media (hover: hover) {}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  body {
    margin: 0;
    /*display: flex; */
    place-items: center;
  }

  /*  #app {
   display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 2rem;
  }*/

  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }



}

.card-sections {
  background: var(--color-background-card) !important;
}

.card-forms {
  background: var(--color-background-card-forms) !important;
}
</style>
