<template>
  <div class="navbar navbar-default navbar-fixed-bottom nav-bar-credit">
    <div class="container" style="justify-content: center">
      <button-credit
        :onClickCredit="onClickCreditHanle"
        routeNamePush="self-enrollment"
        description="Solicita tu crédito"
      />
    </div>
  </div>
</template>

<script>
import ButtonCredit from "./atoms/ButtonCredit.vue";
import {  mapActions } from "vuex";

export default {
  name: "nav-bar-credit",
  components: {
    ButtonCredit,
  },
  props: {
    hideLoan: {
      type: Function,
    },
  },
  methods: {
    ...mapActions(["changeStep","hideNavBar"],),
    onClickCreditHanle() {
      
      this.changeStep(1);
      this.hideNavBar();
    },
  },
};
</script>

<style >
.nav-bar-credit {
  position: fixed !important;
  bottom: 0;
  width: 100%;
  background: var(--color-background-card);
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 15%);
}
</style>

