<script>
import PresentationSection from "@/app/components/sections/PresentationSection.vue";
import ServiceSection from "@/app/components/sections/ServiceSection.vue";
import RequirementSection from "@/app/components/sections/RequirementSection.vue";
import StepsSection from "@/app/components/sections/StepsSection.vue";
import CommentSection from "@/app/components/sections/CommentSection.vue";

export default {
  components: {
    PresentationSection,
    ServiceSection,
    RequirementSection,
    StepsSection,
    CommentSection,
  },
  data: () => ({
    windowWidth: window.innerWidth,
    showButtonCredit: false,
    showMobileImage: false,
  }),
  watch: {
    windowWidth(newWidth, oldWidth) {
      this.changeShowCredit();
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.changeShowCredit();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    changeShowCredit() {
      if (this.windowWidth < 600) {
        this.showButtonCredit = false;
        this.showMobileImage = true;
      } else {
        this.showButtonCredit = true;
        this.showMobileImage = false;
      }
    },
  },
};
</script>

<template>
  <main>
    <PresentationSection :showButtonCredit="showButtonCredit" />
    <ServiceSection :showMobileImage="!showMobileImage"/>
    <RequirementSection :showButtonCredit="showButtonCredit" />
    <StepsSection />
    <CommentSection :showButtonCredit="showButtonCredit" />
  </main>
</template>

<style scoped>
</style>
