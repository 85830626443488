<template>
  <section id="comment-section">
    <div class="comment">
      <div class="row m-0 pb-2">
        <div class="col-lg-12">
          <div class="section-title p-0 card pb-2 card-seccions">
            <h2 class="mt-3 mb-3">Lo que dicen de LanaPa'ti</h2>
            <div class="card-body">
              <div class="row" style="justify-content: center">
                <div class="col-lg-10 col-md-10">
                  <carousel
                    :items-to-show="itemsToshow"
                    :autoplay="3000"
                    :wrapAround="true"
                  >
                    <slide v-for="slide in slides" :key="slide.id">
                      <!--{{ slide }} -->

                      <div class="carousel__item info-box mb-4 mt-4 item-slice">
                        <h3>{{ slide.name }}</h3>
                        <p>{{ slide.comment }}</p>
                      </div>
                    </slide>

                    <template #addons>
                      <navigation />
                      <pagination v-if="showArrows" />
                    </template>
                  </carousel>
                </div>
              </div>
            </div>
            <a href="https://play.google.com/store/apps/details/Lana_Pa_Ti?id=com.fiinlab.wallet&hl=es_DO" >
            <button-credit description="Descarga la app"/>
          </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import {  mapActions } from "vuex";

import ButtonCredit from "../atoms/ButtonCredit.vue";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ButtonCredit,
  },
  props: ["showButtonCredit"],
  data: () => ({
    appOn: process.env.VUE_APP_ON,
    windowWidth: window.innerWidth,
    itemsToshow: 3.2,
    showArrows: true,
    slides: [
      {
        id: 1,
        name: "Alicia",
        comment:
          '"El crédito es muy accesible y muy bueno por que puedes disponer de tu dinero aunque no hayas terminado de liquidarlo"',
      },
      {
        id: 2,
        name: "Ricardo Israel",
        comment: '"El trámite es sencillo, los pagos son cómodos y rápidos."',
      },
      {
        id: 3,
        name: "Leonardo",
        comment:
          '"Es una aplicación fácil de descargar, fácil de obtener un crédito y si eres puntual con tus pagos se te aumenta el crédito."',
      },
      {
        id: 4,
        name: "Eleazar",
        comment:
          '"Porque para mi, es una excelente oportunidad de préstamo por los págos muy comodos."',
      },
      {
        id: 5,
        name: "Beatriz Irene",
        comment:
          '"Fácil rápido sin tanto trámite y si eres puntual te aumentan la línea de crédito."',
      },
      {
        id: 6,
        name: "Maria Ines",
        comment:
          '"Es muy fácil de obtener el crédito y sus formas de pago son muy cómodas."',
      },
    ],
  }),
  watch: {
    windowWidth(newWidth, oldWidth) {
      this.changeItemsToShow();
    },
  },
  methods: {
    ...mapActions(["changeStep"],),
    registerLoan() {
      this.changeStep(1);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    changeItemsToShow() {
      if (this.windowWidth < 600) {
        this.itemsToshow = 1.5;
        this.showArrows = true;
      } else if (this.windowWidth < 950) {
        this.itemsToshow = 2.2;
        this.showArrows = false;
      } else {
        this.itemsToshow = 3.2;
        this.showArrows = false;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.changeItemsToShow();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.service-slice {
  background: var(--color-background-gray);
}
.item-slice {
  padding: 5px;
  height: 200px;
  width: 200px;
  border-radius: 10px;
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
  border: 0;
}

.section-title h2 {
  font-size: 1.8rem;
  font-weight: 600;

  position: relative;
  color: var(--color-text-card);
}

.info-box {
  color: var(--color-text-card-body);
  text-align: center;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 15%);
  border-radius: 10px;
  /*background: #fff;*/
  padding: 20px 0 30px 0;
  height: auto;
  width: 80%;
}

.info-box i {
  font-size: 32px;
  color: var(--vc-clr-primary);
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #fef5f4;
}

.info-box h3 {
  font-size: 20px;
  color: var(--color-text-name);
  font-weight: 700;
  margin: 10px 0;
}

.info-box p {
  padding: 5px;
  line-height: 24px;
  font-size: 0.9rem;
  margin-bottom: 0;
}
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
  border: 2px solid var(--vc-clr-primary);
}
</style>