<template>
  <div class="text-center">
    <button type="submit" @click="loanRegister">{{ description }}</button>
  </div>
</template>

<script>

const { VUE_APP_LINK_APP } = process.env;

export default {
  name: "button-credit",

  props: {
    description: {
      default: "button",
    },
    onClickCredit: {
      type: Function,
    },
    routeNamePush: {},
  },
  methods: {
    loanRegister() {
      
      
      if (this.routeNamePush) {
        // window.open(VUE_APP_LINK_APP, '_blank');

        this.$router.push({ name: this.routeNamePush });
      }

      if (!this.onClickCredit) {
        return;
      }
     
      this.onClickCredit();
    
    },
  },
};
</script>

<style scoped>
button[type="submit"] {
  font-weight: 600;
  background: var(--vc-clr-primary);
  border: 0;
  border-radius: 14px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

button[type="submit"]:hover {
  background: var(--vc-c-green);
}

.btn-left {
  text-align: left !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.custom-width button{
  width: 90%;
}
.custom-login{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 40%;

}

</style>